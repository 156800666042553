import React, {useEffect, useState} from "react";
import {Food} from "./Items";
import {center, classNames, distance, Point, pxUnit, repeat, vwUnit} from "./utils";
import {getSquirrelMouthPoint, setSquirrelHeadZIndex} from "./Part";

// draggable-food width/height in App.css (36vw):
const FOOD_SIZE_VW = 36 / 100;

// the factor by which the food is resized when it reaches the mouth:
const FOOD_EATING_SIZE_FACTOR = 60 / 100;

const EATING_FRAME_MS = 80;

const FALLING_FRAME_MS = 60;

const getStartPosition = () => {
  const {iw, ih} = { iw: window.innerWidth, ih: window.innerHeight};
  const foodSizeVw = iw * FOOD_SIZE_VW;
  return {
    x: (iw - foodSizeVw) / 2,
    y: ih - foodSizeVw - iw * 12 / 100
  };
};

export function Feeder(props: {
  food: Food,
  index: number,
  onSuccess(): void,
  setEating(eating: boolean): void;
  onFail(): void,
}) {
  const {food, index, setEating, onSuccess} = props;

  const scale = food.scale ?? 1;

  const parts = food.parts;

  const [remaining, setRemaining] = useState(parts.length);

  const [position, setPosition] = useState<Point>(getStartPosition);

  const [dragStart, setDragStart] = useState<Point | null>(null);

  const [eatingStep, setEatingStep] = useState(0);

  const startDrag: React.PointerEventHandler = event => {
    setDragStart({x: event.clientX, y: event.clientY});
  }

  const moveDrag: React.PointerEventHandler = event => {
    if (dragStart !== null) {
      const startPosition = getStartPosition();
      setPosition({
        x: startPosition.x + event.clientX - dragStart.x,
        y: startPosition.y + event.clientY - dragStart.y
      });
    }
  }

  const endDrag: React.PointerEventHandler = () => {
    setDragStart(null);
    const foodRect = getFoodRect();
    const foodRadius = Math.max(foodRect.w, foodRect.h);
    const foodCenter = center(foodRect);
    const endPosition = getSquirrelMouthPoint(index);
    const dist = distance(foodCenter, endPosition);
    if (dist > foodRadius) {
      console.log(`too far away, distance: ${Math.round(dist)} / ${Math.round(foodRadius)} (${foodCenter.x}<->${endPosition.x} | ${foodCenter.y}<->${endPosition.y})`);
      setPosition(getStartPosition());
    } else {
      // set optimal eating position:
      setPosition({
        x: endPosition.x - (foodRect.x - position.x + foodRect.w / 2),
        y: endPosition.y - (foodRect.y - position.y + foodRect.h)
      });
      setSquirrelHeadZIndex(index, "9999");
      setEatingStep(Math.ceil(foodRect.h * .7 / (4 * window.innerWidth / 1080)));
      setEating(true);
    }
  }

  useEffect(() => {
    if (eatingStep !== 0) {
      if (eatingStep > 1) {
        setTimeout(() => setEatingStep(eatingStep => eatingStep - 1), EATING_FRAME_MS);
      } else if (eatingStep < -1) {
        setTimeout(() => setEatingStep(eatingStep => eatingStep + 1), FALLING_FRAME_MS);
      } else {
        setEating(false);
        if (eatingStep === 1 && food.hasShell) {
          // food has shell: drop it!
          setEatingStep(-12);
        } else {
          setPosition(getStartPosition());
          setRemaining(remaining => remaining - 1);
          setEatingStep(0);
        }
      }
    } else {
      setSquirrelHeadZIndex(index, "inherit");
    }
  }, [index, eatingStep, setEating, food.hasShell]);

  useEffect(() => {
    if (remaining === 0) {
      onSuccess();
    }
  }, [index, remaining, onSuccess]);

  const backgroundSize = scale * FOOD_SIZE_VW * 100;

  const getSizeFactor = () => {
    const startY = getStartPosition().y;
    const iw = window.innerWidth;
    const endY = getSquirrelMouthPoint(index).y - iw * FOOD_SIZE_VW * FOOD_EATING_SIZE_FACTOR;
    return (position.y - startY) * (1 - FOOD_EATING_SIZE_FACTOR) / (startY - endY) + 1;
  };

  const getFoodRect = () => {
    const iw = window.innerWidth;
    const sizeFactor = getSizeFactor();
    const foodScaledOffset = iw * FOOD_SIZE_VW * (1 - scale * sizeFactor) / 2;
    const foodPx = (px: number): number => px / 1024 * scale * sizeFactor * iw * FOOD_SIZE_VW;
    const currentPartRect = parts[remaining - 1];
    return {
      x: position.x + foodScaledOffset + foodPx(currentPartRect.x),
      y: position.y + foodScaledOffset + foodPx(currentPartRect.y),
      w: foodPx(currentPartRect.w),
      h: foodPx(currentPartRect.h),
    };
  }

  /*
    const debugRect = remaining && getFoodRect();
    const endPosition = getSquirrelMouthPoint(index);
    const debugRect = {
      x: endPosition.x - 10,
      y: endPosition.y - 10,
      w: 20,
      h: 20
    }
  */

  return (
      <>
        <div className="modal-shim"
             onClick={() => {
               if (eatingStep === 0) { // do not disturb while eating!
                 props.onFail();
               }
             }}
        />
        {
          repeat(remaining - 1, (index) =>
              <div key={"food-" + index}
                   className={`draggable-food ${props.food.icon}-${index + 1}`}
                   style={{
                     backgroundSize: vwUnit(backgroundSize),
                   }}
              />
          )
        }
        <div className={classNames`draggable-food ${props.food.icon + "-" + remaining} ${eatingStep < 0} shell-falling`}
             style={{
               left: pxUnit(position.x),
               top: pxUnit(position.y + (eatingStep >= 0 ? eatingStep : 2 * Math.pow(12 + eatingStep, 2)) * (4 * window.innerWidth / 1080)),
               backgroundSize: vwUnit(backgroundSize * getSizeFactor()),
               opacity: -4 < eatingStep && eatingStep < 0 ? eatingStep / -4 : undefined
             }}
             onPointerDown={eatingStep ? undefined : startDrag}
             onPointerMove={eatingStep ? undefined :  moveDrag}
             onPointerUp={eatingStep ? undefined :  endDrag}
        />
        {
          /*
          debugRect &&
            <div style={{
              position: "absolute",
              left: pxUnit(debugRect.x - 1),
              top: pxUnit(debugRect.y + eatingStep * (4 * window.innerWidth / 1080) - 1),
              width: pxUnit(debugRect.w),
              height: pxUnit(debugRect.h),
              border: "1px solid black",
              pointerEvents: "none",
            }}/>
           */
        }
      </>
  );
}