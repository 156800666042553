import React, {useEffect, useRef} from "react";
import {getPreloadedImage, scaledPx as scaledVw} from "./utils";

const getSquirrelPartElementId = (id: string, index: number) => `${id}-${index}`;

const getSquirrelPart = (id: string, index: number): HTMLElement =>
    document.getElementById(getSquirrelPartElementId(id, index))!;

export const getSquirrelMouthPoint = (index: number) => {
  const squirrelHead = getSquirrelPart("head", index);
  const squirrel = squirrelHead.offsetParent as HTMLElement;
  return {
    x: squirrelHead.offsetLeft + squirrel.offsetLeft + squirrelHead.offsetWidth / 2,
    y: squirrelHead.offsetTop + squirrel.offsetTop + squirrelHead.offsetHeight
  };
};

export const setSquirrelHeadZIndex = (index: number, zIndex: string) => {
  getSquirrelPart("head", index).style.zIndex = zIndex;
};

export function Part(props: {
  show: boolean,
  name: string,
  index: number,
  transform?: string,
  transformOrigin?: [number, number],
  color: string,
  step?: number,
  sheetCols?: number,
  sheetRows?: number,
  top?: number,
  left?: number,
  width: number,
  height: number,
  scale: number,
}) {
  const scaledPx = (px?: number) => scaledVw(Math.round((px ?? 0) * props.scale));
  const offsetX = props.sheetCols ? props.width * ((props.step ?? 0) % props.sheetCols) : 0;
  const offsetY = props.sheetCols ? props.height * Math.floor((props.step ?? 0) / props.sheetCols) : 0;
  const imageWidth = props.width * (props.sheetCols ?? 1);
  const imageHeight = props.height * (props.sheetRows ?? 1);

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas: HTMLCanvasElement = canvasRef.current!;
    const width = canvas.width;
    const height = canvas.height;
    const context = canvas.getContext("2d")!;
    context.clearRect(0, 0, width, height);
    context.globalCompositeOperation = "source-over";
    context.drawImage(getPreloadedImage(props.name), 0, 0);
    context.globalCompositeOperation = "source-in";
    context.fillStyle = props.color;
    context.fillRect(0, 0, width, height);
    context.globalCompositeOperation = "source-over";
    context.drawImage(getPreloadedImage(props.name + "-outline"), 0, 0);
  }, [props.name, props.color]);

  return (
      <div style={{
        display: props.show ? undefined : "none",
        position: "absolute",
        top: scaledPx(props.top),
        left: scaledPx(props.left),
        width: scaledPx(props.width),
        height: scaledPx(props.height),
        transformOrigin: props.transformOrigin && scaledPx(props.transformOrigin[0]) + " " + scaledPx(props.transformOrigin[1]),
        transform: props.transform,
        overflow: "hidden",
      }}
           id={getSquirrelPartElementId(props.name, props.index)}
      >
        <canvas
            ref={canvasRef}
            style={{
              width: scaledPx(imageWidth),
              height: scaledPx(imageHeight),
              marginLeft: scaledPx(-offsetX),
              marginTop: scaledPx(-offsetY),
            }}
            width={imageWidth}
            height={imageHeight}
            data-part={props.name}
        />
      </div>
  )
}