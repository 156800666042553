import jumpOntoArea from "./tricks/jump-onto.png";
import jumpOverArea from "./tricks/jump-over.png";
import wagTailArea from "./tricks/wag-tail.png";
import stretchArea from "./tricks/stretch.png";
import {Rect} from "./utils";

export interface Item {
  name: string;
  icon: string;
  price: number;
  xp: number;
  unlockLevel: number;
}

export interface Food extends Item {
  parts: Rect[],
  scale?: number,
  hasShell?: boolean,
}

export const AllFood: Food[] = [
  {
    name: "Hazelnut",
    icon: "food-hazelnut",
    price: 10,
    xp: 10,
    unlockLevel: 0,
    parts: [
        {x: 249, y: 251, w: 548, h: 537},
    ],
  },
  {
    name: "Walnut",
    icon: "food-walnut",
    price: 20,
    xp: 15,
    unlockLevel: 3,
    scale: 1.25,
    parts: [
      {x: 260, y: 274, w: 554, h: 391},
    ],
    hasShell: true,
  },
  {
    name: "Carrot Pieces",
    icon: "food-carrot-pieces",
    price: 30,
    xp: 30,
    unlockLevel: 5,
    scale: 1.5,
    parts: [
      {x: 209, y: 357, w: 299, h: 216},
      {x: 272, y: 463, w: 403, h: 247},
      {x: 540, y: 313, w: 307, h: 282},
    ],
  },
  {
    name: "Apple Pieces",
    icon: "food-apple-pieces",
    price: 35,
    xp: 40,
    unlockLevel: 7,
    parts: [
      {x: 506, y: 513, w: 320, h: 210},
      {x: 452, y: 635, w: 215, h: 181},
      {x: 203, y: 293, w: 221, h: 337},
      {x: 300, y: 523, w: 220, h: 198},
      {x: 494, y: 207, w: 200, h: 254},
      {x: 401, y: 303, w: 199, h: 266},
      {x: 563, y: 347, w: 196, h: 292},
    ],
    scale: 1.25,
  },
  {
    name: "Sunflower Seeds",
    icon: "food-sunflower-seeds",
    price: 50,
    xp: 55,
    unlockLevel: 9,
    parts: [
      {x: 197, y: 498, w: 250, h: 211},
      {x: 537, y: 520, w: 292, h: 147},
      {x: 389, y: 516, w: 146, h: 250},
      {x: 493, y: 515, w: 141, h: 239},
      {x: 209, y: 383, w: 294, h: 143},
      {x: 349, y: 393, w: 159, h: 240},
      {x: 479, y: 374, w: 232, h: 232},
      {x: 348, y: 264, w: 279, h: 149},
    ],
    scale: 1.25,
  },
];

export interface Trick extends Item {
  traceArea: string;
  start: [number, number];
  end: [number, number];
  cooldown: number;
}

export const AllTricks: Trick[] = [
  {
    name: "Stretch",
    icon: "trick-stretch",
    price: -20,
    xp: 0,
    unlockLevel: 0,
    traceArea: stretchArea,
    start: [516, 1012],
    end: [512, 174],
    cooldown: 1,
  },
  {
    name: "Wag Tail",
    icon: "trick-wag-tail",
    price: -30,
    xp: 0,
    unlockLevel: 2,
    traceArea: wagTailArea,
    start: [760, 1008],
    end: [334, 168],
    cooldown: 2,
  },
  {
    name: "Jump Over",
    icon: "trick-jump-over",
    price: -35,
    xp: 0,
    unlockLevel: 4,
    traceArea: jumpOverArea,
    start: [100, 710],
    end: [887, 660],
    cooldown: 4,
  },
  {
    name: "Jump Through",
    icon: "trick-jump-through",
    price: -40,
    xp: 0,
    unlockLevel: 6,
    traceArea: jumpOverArea,
    start: [100, 710],
    end: [887, 660],
    cooldown: 6,
  },
  {
    name: "Jump Onto",
    icon: "trick-jump-onto",
    price: -45,
    xp: 0,
    unlockLevel: 8,
    traceArea: jumpOntoArea,
    start: [132, 1060],
    end: [920, 450],
    cooldown: 10,
  },
];

export interface Level {
  neededXP: number;
  item?: Food | Trick;
  coins?: number;
  gems?: number;
}

export const AllLevels: Level[] = [
  {
    neededXP: 25,
    coins: 20,
    gems: 10,
  },
  {
    neededXP: 70,
    item: AllTricks[1],
    gems: 5,
  },
  {
    neededXP: 200,
    item: AllFood[1],
    coins: 70,
  },
  {
    neededXP: 270,
    item: AllTricks[2],
    gems: 15,
  },
  {
    neededXP: 530,
    item: AllFood[2],
    gems: 20,
  },
  {
    neededXP: 640,
    item: AllTricks[3],
    coins: 120,
  },
  {
    neededXP: 1000,
    item: AllFood[3],
    gems: 10,
  },
  {
    neededXP: 1400,
    item: AllTricks[4],
    gems: 15,
  },
  {
    neededXP: 1700,
    item: AllFood[4],
    coins: 180,
  },
  {
    neededXP: 2000,
    coins: 200,
    gems: 20,
  },
];

export function xpOfNeededXP(level: number, currentXP: number) {
  const maxLevelReached = level === AllLevels.length;
  const neededXP = AllLevels[maxLevelReached ? level - 1 : level].neededXP;
  const xp = maxLevelReached ? neededXP : Math.min(currentXP, neededXP);
  return {xp, neededXP};
}

type Color = string;

export interface Squirrel {
  body: Color,
  belly: Color,
  sweetSpot: string;
  unlockPrice: number;
}

export const AllSquirrels: Squirrel[] = [
  {
    body: "#B8664F",
    belly: "#E4AFA0",
    sweetSpot: "belly",
    unlockPrice: 0,
  },
  {
    body: "#8E675C",
    belly: "#C3A197",
    sweetSpot: "head",
    unlockPrice: 40,
  },
  {
    body: "#BB8464",
    belly: "#ECB594",
    sweetSpot: "right-arm",
    unlockPrice: 70,
  },
  {
    body: "#6B6260",
    belly: "#91837F",
    sweetSpot: "tail",
    unlockPrice: 100,
  },
];

export interface SquirrelState {
  name: string;
  unlocked: boolean;
  level: number;
  xp: number;
  trickRestoreAt: Record<string, number>;
}

const INITIAL_SQUIRREL_STATE: Omit<SquirrelState, "name"> = {
  unlocked: false,
  level: 0,
  xp: 0,
  trickRestoreAt: {},
}

export const AllInitialSquirrelStates: SquirrelState[] = [
  {
    name: "Alana",
    ...INITIAL_SQUIRREL_STATE,
    unlocked: true,
  }, {
    name: "Plum",
    ...INITIAL_SQUIRREL_STATE,
  }, {
    name: "Sandy",
    ...INITIAL_SQUIRREL_STATE,
  }, {
    name: "Zeeb",
    ...INITIAL_SQUIRREL_STATE,
  }
];
