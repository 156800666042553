import React from "react";
import {classNames, vwUnit} from "./utils";
import {AllLevels, Level, xpOfNeededXP} from "./Items";

export function LevelInfo(props: {
  level: number,
  xp: number,
  close(): void,
}) {
  const levelData: Level | undefined = AllLevels[props.level];
  const {xp, neededXP} = xpOfNeededXP(props.level, props.xp);
  const progress = 61.5 * xp / neededXP;
  const isLevelUp = props.xp >= neededXP;
  return (
      <>
        <div className="modal-shim"/>
        <div className={classNames`level-info ${isLevelUp} level-up`}>
          {isLevelUp &&
              <h1>Level Up!</h1>
          }
          <div className="progress-bar-container">
            <p>
              {xp} / {neededXP}
            </p>
            <div className="progress-bar"/>
            <div className="progress-bar"
                 style={{
                   backgroundColor: "#C9615B",
                   clip: `rect(auto, ${vwUnit(progress)}, auto, auto)`,
                 }}
            />
            <div className="level-heart-left">{props.level}</div>
            <div className="level-heart-right">{props.level + 1}</div>
          </div>
          {levelData &&
              <>
                  <h2>
                      Rewards:
                  </h2>
                  <div className="rewards-container">
                    {levelData.item &&
                        <div className="reward">
                            <div className={`reward-item ${levelData.item.icon}`}/>
                        </div>
                    }
                    {levelData.coins &&
                        <div className="reward">
                            <div className="coin-icon">{levelData.coins}</div>
                        </div>
                    }
                    {levelData.gems &&
                        <div className="reward">
                            <div className="gem-icon">{levelData.gems}</div>
                        </div>
                    }
                  </div>
              </>
          }
          <button className="close"
                  onClick={props.close}
          />
        </div>
      </>
  );
}

