import React, {useEffect, useState} from "react";
import {Part} from "./Part";
import {animate, eventController, idleEvents, registerImages} from "./utils";
import squirrelTail from "./animations/idle-tail/sheet.png";
import squirrelTailOutline from "./animations/idle-tail/sheet-outline.png";

registerImages({
  "tail": squirrelTail,
  "tail-outline": squirrelTailOutline,
});

const idleTailFrames = [0, 1, 2, 3, 4, 3, 2, 5];

export function SquirrelTail(props: {
  show: boolean,
  index: number,
  scale: number,
  color: string,
  doTrick: boolean,
  trickDone(): void;
}) {
  const {doTrick, trickDone, show, index} = props;

  const [tailIdleStep, setTailIdleStep] = useState(0);
  const [, setStopTailIdleEvents] = useState<() => void | undefined>();

  const animateTail = (done: () => void) => animate(idleTailFrames.length, 8, setTailIdleStep, done);

  useEffect(() => {
        setStopTailIdleEvents(eventController(
            () => idleEvents("tail", 10000, 30000, done => animateTail(done)),
            show));
      },
      [show]);

  useEffect(() => {
    doTrick && show && animateTail(trickDone);
  }, [doTrick, show, trickDone]);

  return (
      <Part show={show}
            index={index}
            scale={props.scale}
            color={props.color}
            sheetCols={3} sheetRows={2}
            top={40} left={695} width={879} height={1773}
            step={idleTailFrames[tailIdleStep]}
            name="tail"
      />
  );
}