import React, {useEffect, useState} from "react";
import {animate, scaledPx as scaledVw} from "./utils";

const tapSize = 720;
const sheetCols = 4;
const sheetRows = 3;

export function TapEffect(props: {
  leftTopAndIsSweetSpot: [number, number, boolean];
}) {

  const [tapStep, setTapStep] = useState(-1);

  useEffect(() => {
    if (props.leftTopAndIsSweetSpot[0] !== -1) {
      animate(12, 5, setTapStep, () => setTapStep(-1));
    }
  }, [props.leftTopAndIsSweetSpot]);

  const scaledPx = (px?: number) => scaledVw(Math.round((px ?? 0) / 5));
  const offsetX = tapSize * (tapStep % sheetCols);
  const offsetY = tapSize * Math.floor(tapStep / sheetCols);
  const imageWidth = tapSize * sheetCols;
  const imageHeight = tapSize * sheetRows;
  return (
      <>
        {tapStep !== -1 &&
            // offset is in px here, not in vw, because click events return px coordinates:
            <div style={{
              position: "absolute",
              left: props.leftTopAndIsSweetSpot[0] + "px",
              top: props.leftTopAndIsSweetSpot[1] + "px",
            }}>
                <div className="squirrel-tap"
                     style={{
                       position: "absolute",
                       top: scaledPx(-tapSize/2),
                       left: scaledPx(-tapSize/2),
                       width: scaledPx(tapSize),
                       height: scaledPx(tapSize),
                       backgroundSize: scaledPx(imageWidth) + " " + scaledPx(imageHeight),
                       backgroundPosition: scaledPx(-offsetX) + " " + scaledPx(-offsetY),
                       filter: props.leftTopAndIsSweetSpot[2] ? "invert()" : undefined,
                     }}
                />
            </div>
        }
      </>
  );
}