import React from "react";

export function Panel(props: {
  heading: string,
  items: any[],
  close(): void,
}) {
  return (
      <div className={`panel panel-${props.heading.toLowerCase()}`}>
        <h1>{props.heading}</h1>
        <div className="panel-viewport">
          <table className="panel-content">
            <tbody>
            {props.items}
            </tbody>
          </table>
        </div>
        <button className="close"
                onClick={props.close}
        />
      </div>
  );
}

