import React, {useEffect, useRef, useState} from "react";
import {distance, loadImage, Point} from "./utils";
import {Trick} from "./Items";

function pointFromEvent(event: React.PointerEvent<HTMLElement>): Point {
  const canvas: HTMLCanvasElement = event.currentTarget.previousSibling as HTMLCanvasElement;
  const scale = canvas.width / (.8 * window.innerWidth);
  const container = canvas.parentElement!;
  const x = (event.clientX - container.offsetLeft) * scale;
  const y = (event.clientY - container.offsetTop) * scale;
  return {x, y};
}

const LINE_WIDTH = 250;
const LINE_COLOR = "#9BDEDB";

export function Tracer(props: {
  trick: Trick,
  onSuccess(): void,
  onFail(): void,
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [failed, setFailed] = useState(false);

  const {onFail} = props;

  useEffect(() => {
    if (failed) {
      setTimeout(() => {
        onFail();
      }, 1000);
    }
  }, [failed, onFail]);

  useEffect(() => {
    loadImage(props.trick.traceArea).then(fillImage => {
      const canvas = canvasRef.current!;
      const context = canvas.getContext("2d")!;
      context.drawImage(fillImage,
          Math.round((canvas.width - fillImage.width) / 2),
          Math.round((canvas.height - fillImage.height) / 2));
      context.stroke();
      context.globalCompositeOperation = "source-atop";
      context.shadowColor = LINE_COLOR;
      context.shadowBlur = LINE_WIDTH / 10;
      context.lineWidth = LINE_WIDTH;
      context.lineCap = "round";
      context.lineJoin = "round";
      context.strokeStyle = LINE_COLOR;
      context.fillStyle = LINE_COLOR;
    });
  }, [props.trick]);

  let lastPoint: Point | null = null;

  const onPointerDown: React.PointerEventHandler<HTMLElement> = (event) => {
    if (!failed) {
      const point = pointFromEvent(event);
      if (isNearTarget(point, props.trick.start)) {
        lastPoint = pointFromEvent(event);
      } else {
        setFailed(true);
      }
    }
  };

  const onPointerMove: React.PointerEventHandler<HTMLElement> = (event) => {
    if (!lastPoint) {
      return;
    }
    const canvas = event.currentTarget.previousSibling as HTMLCanvasElement;
    const context = canvas.getContext("2d")!;
    const point = pointFromEvent(event);
    const imageData = context.getImageData(point.x, point.y, 1, 1).data;
    if (imageData[3] === 0) {
      lastPoint = null;
      setFailed(true);
    } else {
      if (distance(point, lastPoint) < 10) {
        // close enough to just draw one big circle:
        context.beginPath();
        context.arc(point.x, point.y, LINE_WIDTH / 2, 0, 2 * Math.PI);
        context.closePath();
        context.fill();
      } else {
        // draw thick line from lastPoint to current point:
        context.beginPath();
        context.moveTo(lastPoint.x, lastPoint.y);
        context.lineTo(point.x, point.y);
        context.closePath();
        context.stroke();
      }
      lastPoint = point;
    }
    event.preventDefault();
    event.stopPropagation();
  };

  function isNearTarget(point: Point, targetPoint: [number, number]): boolean {
    //console.log(`tracing at ${point.x} | ${point.y}`);
    const distanceToTarget = distance(point, {x: targetPoint[0], y: targetPoint[1]});
    const result = distanceToTarget < 70;
    if (!result) {
      console.log(`failed: distance to target ${targetPoint[0]}|${targetPoint[1]} too high!`);
    }
    return result;
  }

  const onPointerUp: React.PointerEventHandler<HTMLElement> = (event) => {
    if (!failed) {
      const point = pointFromEvent(event);
      if (lastPoint && isNearTarget(point, props.trick.end)) {
        props.onSuccess();
      } else {
        setFailed(true);
      }
    }
  }

  return (
      <div className="trick-tracer">
        <canvas ref={canvasRef}
                width="1024" height="1120"
                style={failed ? {
                  filter: "drop-shadow(0 0 3vw red)",
                } : undefined}
        />
        <div className={`${props.trick.icon}-trace-outline`}
             onPointerDown={onPointerDown}
             onPointerMove={onPointerMove}
             onPointerUp={onPointerUp}
        />
      </div>
  )
}