import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import {SquirrelView} from "./SquirrelView";
import {Panel} from "./Panel";
import {LevelAndXP} from "./LevelAndXP";
import {ShopItem} from "./ShopItem";
import {
  AllFood,
  AllInitialSquirrelStates,
  AllLevels,
  AllSquirrels,
  AllTricks,
  Food,
  SquirrelState,
  Trick
} from "./Items";
import {TapEffect} from "./TapEffect";
import {Tracer} from "./Tracer";
import {Feeder} from "./Feeder";
import {LevelInfo} from "./LevelInfo";
import {Tutorial} from "./Tutorial";
import {classNames, deepCopy, formatTime, getElementByClassName, modifyData, now, runAnimation} from "./utils";

const stateFromLocalStorage:Record<string, any> = JSON.parse(localStorage.getItem("USER_STATE") ?? "{}");

function App() {
  const [index, setIndex] = useState<number>(stateFromLocalStorage.index ?? 0);
  const [coins, setCoins] = useState<number>(stateFromLocalStorage.coins ?? 0);
  const [gems, setGems] = useState<number>(stateFromLocalStorage.gems ?? 0);
  const [items, setItems] = useState<Record<string, number>>(() => {
    const initialItems: Record<string, any> = {};
    const items = stateFromLocalStorage.items ?? {};
    for (const food of AllFood) {
      initialItems[food.name] = items[food.name] ?? 0;
    }
    return initialItems;
  });
  const [squirrelStates, setSquirrelStates] = useState<SquirrelState[]>(() =>
      stateFromLocalStorage.squirrelStates || deepCopy(AllInitialSquirrelStates));

  const squirrelState = squirrelStates[index];

  const updateName = (name: string): void => modifyData(setSquirrelStates, squirrelStates => {
    squirrelStates[index].name = name;
  });

  useEffect(() => {
    if (squirrelState.xp) {
      const levelAndXpBox = getElementByClassName("level-and-xp");
      runAnimation(levelAndXpBox, "pulse-plus");
    }
  }, [squirrelState.xp]);

  const earnXP = (more: number) => modifyData(setSquirrelStates, squirrelStates => {
    const level = squirrelStates[index].level;
    if (level === AllLevels.length) {
      // max level reached, sorry, no more XP!
      return;
    }
    const nextXP = squirrelStates[index].xp += more;
    const neededXP = AllLevels[level].neededXP;
    if (nextXP >= neededXP) {
      setLevelInfoVisible(true);
    }
  });

  function updateScoreValue(scoreType: string, oldScoreValue: React.MutableRefObject<number>, scoreValue: number): void {
    if (isNaN(oldScoreValue.current)) { // ignore initial scoreValue from local storage
      if (scoreValue !== 0) {
        oldScoreValue.current = scoreValue;
      }
    } else {
      const animation = scoreValue > oldScoreValue.current ? "pulse-plus"
          : scoreValue < oldScoreValue.current ? "pulse-minus"
              : undefined;
      if (animation) {
        const scoreBox = getElementByClassName(`score-box ${scoreType}`);
        runAnimation(scoreBox, animation);
      }
    }
    oldScoreValue.current = scoreValue;
  }

  const earnCoins = (more: number | undefined) => {
    if (more) {
      setCoins(coins => coins + more);
    }
  };
  const spendCoins = (spent: number) => earnCoins(-spent);

  const oldCoins: React.MutableRefObject<number> = useRef(NaN);

  useEffect(() => {
    updateScoreValue("coins", oldCoins, coins);
  }, [coins]);

  const earnGems = (more: number | undefined) => {
    if (more) {
      setGems(gems => gems + more);
    }
  };

  const oldGems = useRef(NaN);

  useEffect(() => {
    updateScoreValue("gems", oldGems, gems);
  }, [gems]);

  useEffect(() => {
    localStorage.setItem("USER_STATE", JSON.stringify({coins, gems, items, squirrelStates}));
  }, [coins, gems, items, squirrelStates]);

  const [clickPoint, setClickPoint] = useState<[number, number, boolean]>([-1, -1, false]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (clickPoint[0] === -1) {
      const partName = (event.target as HTMLElement).getAttribute("data-part");
      if (partName) {
        const isSweetSpot = partName === AllSquirrels[index].sweetSpot;
        setClickPoint([event.clientX, event.clientY, isSweetSpot]);
        setTimeout(() => {
          setClickPoint([-1, -1, false]);
          earnXP(isSweetSpot ? 3 : 1);
        }, 1000);
      }
    }
  };

  const [shopVisible, setShopVisible] = useState(false);

  const [foodVisible, setFoodVisible] = useState(false);

  const [tricksVisible, setTricksVisible] = useState(false);

  const [settingsVisible, setSettingsVisible] = useState(false);

  const [levelInfoVisible, setLevelInfoVisible] = useState(false);

  const [traceTrick, setTraceTrick] = useState<Trick | undefined>(undefined);

  const [doTrick, setDoTrick] = useState<Trick | undefined>(undefined);

  const [tricksNow, setTricksNow] = useState(NaN);

  const tricksTimer = useRef(NaN);

  useEffect(() => {
    if (tricksVisible && Object.keys(squirrelState.trickRestoreAt).length > 0) {
      if (isNaN(tricksTimer.current)) {
        setTricksNow(now());
        tricksTimer.current = window.setInterval(() => {
          setTricksNow(now());
        }, 1000);
      }
    } else {
      if (!isNaN(tricksTimer.current)) {
        window.clearInterval(tricksTimer.current);
        tricksTimer.current = NaN;
      }
    }
  }, [tricksVisible, index, squirrelState.trickRestoreAt]);

  useEffect(() => {
    if (!isNaN(tricksNow)) {
      modifyData(setSquirrelStates, squirrelStates => {
        const trickRestoreAtEntries = Object.entries(squirrelStates[index].trickRestoreAt);
        const newTrickRestoreAtEntries = trickRestoreAtEntries.filter(
            ([_, expirationTime]) => tricksNow < expirationTime
        );
        if (newTrickRestoreAtEntries.length < trickRestoreAtEntries.length) {
          squirrelStates[index].trickRestoreAt = Object.fromEntries(newTrickRestoreAtEntries);
        }
      });
    }
  }, [tricksNow, index]);

  const [feeding, setFeeding] = useState<Food | null>(null);
  const [eating, setEating] = useState(false);

  const isAnyPanelOpen = shopVisible || foodVisible || tricksVisible || settingsVisible;

  const isButtonsDisabled = isAnyPanelOpen || !!feeding || !!traceTrick || !!doTrick;

  const confirmResetState = () => {
    if (window.confirm("Really reset state?")) {
      localStorage.removeItem("USER_STATE");
      window.location.reload();
    }
  };

  const loadStateFromClipboard = async () => {
    try {
      const stateFromClipboard = await navigator.clipboard.readText();
      if (stateFromClipboard) {
        const parsedStateFromClipboard = JSON.parse(stateFromClipboard);
        if (typeof parsedStateFromClipboard.coins === "number" && typeof parsedStateFromClipboard.gems === "number") {
          if (window.confirm("Really overwrite state with clipboard content?")) {
            localStorage.setItem("USER_STATE", stateFromClipboard);
            window.location.reload();
          }
          return;
        }
      }
    } catch (e) {
      // ignore
    }
    window.alert("Could not find valid state in clipboard.");
  }

  const saveStateToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify({coins, gems, items, squirrelStates}));
      window.alert("Successfully copied state to clipboard.");
    } catch (e) {
      window.alert("Could not copy state to clipboard.");
    }
  }

  const prevNextButton = (next: boolean) => {
    return <div
        className={classNames`change-squirrel-arrow ${next} next : previous ${isButtonsDisabled}? disabled`}
        onClick={() => !isButtonsDisabled && setIndex(i => (i + (next ? 1 : 3)) % 4)}
    />;
  };

  const earnTrick = (currentIndex: number) =>
      currentIndex === index ? () =>
          setDoTrick(doTrick => {
            if (doTrick) {
              earnCoins(-doTrick.price);
              modifyData(setSquirrelStates, squirrelStates => {
                squirrelStates[currentIndex].trickRestoreAt[doTrick.name] = now() + 60000 * doTrick.cooldown;
              });
            }
            return undefined;
          }) : () => {
      };

  return (
      <div className={classNames`App ${isAnyPanelOpen} panel-open`} onClick={handleClick}>
        <div className="background"/>
        <div className="squirrel-location">
          {squirrelStates.map((currentSquirrelState, currentIndex) =>
              currentSquirrelState.unlocked &&
              <SquirrelView
                  key={currentIndex}
                  show={currentIndex === index}
                  scale={.58}
                  index={currentIndex}
                  doTrick={currentIndex === index ? doTrick : undefined}
                  trickDone={earnTrick(currentIndex)}
                  eating={eating}
              />
          )}
        </div>
        <TapEffect
            leftTopAndIsSweetSpot={clickPoint}
        />

        <input type="text"
               className="squirrel-name"
               value={squirrelState.name}
               disabled={isButtonsDisabled}
               onChange={event => updateName(event.target.value)}
        />

        <div className={classNames`open-shop-button ${isButtonsDisabled} disabled`}
             onClick={() => !isButtonsDisabled && setShopVisible(true)}
        />
        <div className={classNames`open-food-button ${isButtonsDisabled} disabled`}
             onClick={() => !isButtonsDisabled && setFoodVisible(true)}
        />
        <div className={classNames`open-tricks-button ${isButtonsDisabled} disabled`}
             onClick={() => !isButtonsDisabled && setTricksVisible(true)}
        />

        <LevelAndXP
            xp={squirrelState.xp}
            level={squirrelState.level}
            onClick={() => setLevelInfoVisible(true)}
        />

        {!squirrelState.unlocked &&
            <>
              <div className="modal-shim squirrel-locked"/>
              <div className="squirrel-lock">
                <button
                    disabled={gems < AllSquirrels[index].unlockPrice}
                    onClick={() => modifyData(setSquirrelStates, squirrelStates => {
                      const unlockPrice = AllSquirrels[index].unlockPrice;
                      if (gems >= unlockPrice) {
                        squirrelStates[index].unlocked = true;
                        earnGems(-unlockPrice);
                      }
                    })}>
                  Unlock<br/>for <span className="gem-icon"/>{AllSquirrels[index].unlockPrice}
                </button>
              </div>
            </>
        }

        <div className={classNames`settings-button ${isButtonsDisabled} disabled`}
             onClick={() => {
               if (!isButtonsDisabled) {
                 setSettingsVisible(true);
               }
             }}
        />

        {prevNextButton(false)}
        {prevNextButton(true)}

        {squirrelState.unlocked && squirrelState.level === 0 &&
            !isAnyPanelOpen && !levelInfoVisible && !settingsVisible &&
            !feeding && !traceTrick && !doTrick &&
            <Tutorial/>
        }
        {shopVisible &&
            <Panel
                heading="Shop"
                close={() => setShopVisible(false)}
                items={AllFood.map(food =>
                    <ShopItem
                        key={food.name}
                        name={food.name}
                        icon={food.icon}
                        label={
                          <span className="coin-icon">{food.price}</span>
                        }
                        badge={items[food.name]}
                        onClick={disabled => {
                          if (!disabled) {
                            spendCoins(food.price);
                            modifyData(setItems, inventory => {
                              inventory[food.name]++;
                            });
                          }
                        }}
                        disabled={coins < food.price}
                        unlocked={squirrelState.level >= food.unlockLevel}
                    />
                )}
            />
        }
        {foodVisible &&
            <Panel
                heading="Food"
                close={() => setFoodVisible(false)}
                items={AllFood.map(food =>
                    <ShopItem
                        key={food.name}
                        name={food.name}
                        icon={food.icon}
                        label={`+ ${food.xp} XP`}
                        badge={items[food.name]}
                        onClick={disabled => {
                          if (squirrelState.unlocked) {
                            setFoodVisible(false);
                            if (disabled) {
                              setShopVisible(true);
                            } else {
                              setFeeding(food);
                            }
                          }
                        }}
                        disabled={items[food.name] === 0}
                        disabledLabel="+"
                        unlocked={squirrelState.level >= food.unlockLevel}
                    />
                )}
            />
        }
        {tricksVisible &&
            <Panel
                heading="Tricks"
                close={() => setTricksVisible(false)}
                items={AllTricks.map(trick =>
                    <ShopItem
                        key={trick.name}
                        name={trick.name}
                        icon={trick.icon}
                        label={
                          <span className="coin-icon">{-trick.price}</span>
                        }
                        onClick={disabled => {
                          if (!disabled) {
                            setTricksVisible(false);
                            setTraceTrick(trick);
                          }
                        }}
                        disabled={!squirrelState.unlocked || squirrelState.trickRestoreAt[trick.name] > tricksNow}
                        disabledLabel={squirrelState.unlocked ? formatTime(squirrelState.trickRestoreAt[trick.name] - tricksNow) : undefined}
                        unlocked={squirrelState.level >= trick.unlockLevel}
                    />
                )}
            />
        }
        {settingsVisible &&
            <>
                <div className="modal-shim"/>
                <div className="panel">
                    <h1>Settings</h1>
                    <p>
                        <button onClick={saveStateToClipboard}>Save State to Clipboard</button>
                    </p>
                    <p>
                        <button onClick={loadStateFromClipboard}>Load State from Clipboard</button>
                    </p>
                    <p>
                        <button onClick={confirmResetState}>Reset State</button>
                    </p>
                  {window.location.hash === "#cheat" &&
                      <>
                          <h2>Cheats</h2>
                          <label>Coins:
                              <input type="number" value={coins} min="0"
                                     onChange={event => setCoins(Number(event.target.value))}/>
                          </label>
                          <label>Gems:
                              <input type="number" value={gems} min="0"
                                     onChange={event => setGems(Number(event.target.value))}/>
                          </label>
                          <label>XP:
                              <input type="number" value={squirrelState.xp} min="0"
                                     onChange={event => modifyData(setSquirrelStates, squirrelStates => squirrelStates[index].xp = Number(event.target.value))}/>
                          </label>
                          <label>Level:
                              <input type="range" value={squirrelState.level} max={AllLevels.length}
                                     onChange={event => modifyData(setSquirrelStates, squirrelStates => squirrelStates[index].level = Number(event.target.value))}/>
                              <span>{squirrelState.level}</span>
                          </label>
                          <p>
                              <button onClick={() => modifyData(setSquirrelStates, squirrelStates => {
                                squirrelStates[index].trickRestoreAt = {};
                              })}>Reset Trick Cooldowns
                              </button>
                          </p>
                      </>
                  }
                    <button className="close" onClick={() => setSettingsVisible(false)}/>
                </div>
            </>
        }
        <div className="score-box coins">
          <div className="icon"/>
          {coins}
        </div>
        <div className="score-box gems">
          <div className="icon"/>
          {gems}
        </div>
        {feeding &&
            <Feeder food={feeding}
                    index={index}
                    setEating={setEating}
                    onSuccess={() => {
                      setFeeding(feeding => {
                        if (feeding) {
                          earnXP(feeding.xp);
                          modifyData(setItems, inventory => {
                            inventory[feeding.name]--;
                          });
                        }
                        return null;
                      });
                    }}
                    onFail={() => {
                      setFeeding(null);
                    }}/>
        }
        {traceTrick &&
            <Tracer
                trick={traceTrick}
                onSuccess={() => {
                  setDoTrick(traceTrick);
                  setTraceTrick(undefined);
                }}
                onFail={() => setTraceTrick(undefined)}
            />
        }
        {levelInfoVisible &&
            <LevelInfo
                level={squirrelState.level}
                xp={squirrelState.xp}
                close={() => {
                  if (squirrelState.level < AllLevels.length) {
                    const levelData = AllLevels[squirrelState.level];
                    const neededXP = levelData.neededXP;
                    if (squirrelState.xp >= neededXP) {
                      modifyData(setSquirrelStates, squirrelStates => {
                        squirrelStates[index].xp -= neededXP;
                        squirrelStates[index].level++;
                      });
                      earnGems(levelData.gems);
                      earnCoins(levelData.coins);
                    }
                  }
                  setLevelInfoVisible(false);
                }}
            />
        }
      </div>
  );
}

export default App;
