export function randomInt(min: number, max: number): number {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt;
}

export function addIdleEventListener(name: string, stepCount: number, stepFrames: number, minWaitMs: number, maxWaitMs: number, callback: (step: number) => void): () => void {
  return idleEvents(name, minWaitMs, maxWaitMs, done => animate(stepCount, stepFrames, callback, done));
}

export function idleEvents(name: string, minWaitMs: number, maxWaitMs: number, callback: (done: () => void) => void): () => void {
  //console.log(`starting idle events "${name}"`);

  let alive: boolean = true;
  let timeoutHandle: number = NaN;

  function fireIdleEvent() {
    timeoutHandle = window.setTimeout(() => {
      if (!isNaN(timeoutHandle)) {
        timeoutHandle = NaN;
        callback(() => {
          if (alive) {
            fireIdleEvent();
          }
        });
      }
    }, randomInt(minWaitMs, maxWaitMs));
  }

  fireIdleEvent();

  return () => {
    //console.log(`stopping idle events "${name}"`);
    alive = false;
    if (!isNaN(timeoutHandle)) {
      window.clearTimeout(timeoutHandle);
      timeoutHandle = NaN;
    }
  }
}

export function animate(stepCount: number, stepFrames: number, callback: (step: number) => void,
                        animationEnd: () => void): () => void {
  let currentStep: number = 0;
  function fireStep() {
    callback(currentStep % stepCount);
    ++currentStep;
    if (currentStep <= stepCount) {
      setTimeout(fireStep, stepFrames * 1000 / 60);
    } else {
      animationEnd();
    }
  }
  fireStep();
  return () => { currentStep = stepCount };
}

export function eventController(startEvents: () => (() => void), active: boolean) {
  return (stopEvents: (() => void) | undefined): (() => void) | undefined => {
    if (!stopEvents) {
      if (active) {
        stopEvents = startEvents();
      }
    } else if (!active) {
      stopEvents();
      stopEvents = undefined;
    }
    return stopEvents;
  }
}

const toClassNames = (strings: any[]) => strings.map(s => typeof s === "string" && s.trim()).filter(s => s).join(" ");

export const classNames: (strings: TemplateStringsArray, ...exprs: (string | boolean)[]) => string =
    (strings, ...exprs) => {
      const result: string[] = [];
      let lastFlag = true;
      let lastString = strings[0];
      for (let i = 0; i <= exprs.length; ++i) {
        const expr = exprs[i];
        const string = strings[i + 1];
        if (typeof expr === "string") {
          lastString += expr + string;
        } else {
          const parts = lastString.split(":");
          result.push(parts[lastFlag ? 0 : 1]);
          lastFlag = expr;
          lastString = string;
        }
      }
      return toClassNames(result);
    };

export const vwUnit = (vw: number): string => `${vw.toFixed(4)}vw`;

export const scaledPx = (px: number): string => vwUnit(px * 100 / 1080);

export const pxUnit = (px: number): string => `${px.toFixed(2)}px`;

export type Point = { x: number, y: number };

export const distance = (a: Point, b: Point): number => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return Math.sqrt(dx * dx + dy * dy);
};

export type Rect = { x: number, y: number, w: number, h: number };

export const center = (rect: Rect): Point => ({x: rect.x + rect.w / 2, y: rect.y + rect.h / 2});

export const newImage = (src: string): HTMLImageElement => Object.assign(new Image(), { src });

export const loadImage = (src: string): Promise<HTMLImageElement> => loadImg(newImage(src));

export const loadImg = (img: HTMLImageElement): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onabort = img.onerror = e => reject(e);
    });

const imageRegistry: Record<string, HTMLImageElement> = {};

const mapValues = <V, M>(record: Record<string, V>, mapper: (value: V) => M): Record<string, M> =>
    Object.fromEntries(Object.entries(record).map(
        ([key, value]) => [key, mapper(value)]));

export function registerImages(nameSrcMap: Record<string, string>): void {
  Object.assign(imageRegistry, mapValues(nameSrcMap, src => newImage(src)));
}

export const preloadImages = (): Promise<void> =>
    Promise.all(Object.values(imageRegistry).map(loadImg)).then(undefined);

export const getPreloadedImage = (name: string): HTMLImageElement => imageRegistry[name];

export function repeat<T>(times: number, callback: (n: number) => T): T[] {
  const result = [];
  for (let i = 0; i < times; ++i) {
    result.push(callback(i));
  }
  return result;
}

export function deepCopy<T>(data: T): T;
export function deepCopy(data: any): any {
  if (Array.isArray(data)) {
    return (data as unknown as any[]).map(deepCopy);
  } else if (data && typeof data === "object") {
    return Object.fromEntries(Object.entries(data as Record<string, any>)
    .map(([key, value]) => ([key, deepCopy(value)])));
  }
  return data;
}

export function modifyData<T>(setData: (value: (prevState: T) => T) => void, modifier: (data: T) => void): void {
  setData(data => {
    const copiedData = deepCopy(data);
    modifier(copiedData);
    return copiedData;
  });
}

export const runAnimation = (element: Element, animation: string): void => {
  const oldClassNames = element.className.split(" ");
  if (!oldClassNames.includes(animation)) {
    element.className = oldClassNames.concat(animation).join(" ");
    const listener = () => {
      element.className = oldClassNames.join(" ");
      element.removeEventListener("animationend", listener);
    };
    element.addEventListener("animationend", listener);
  }
};

export const now = () => new Date().getTime();

const pad2zero = (number: number): string => String(number).padStart(2, "0");

export const formatTime = (ms: number): string => {
  const seconds = (ms / 1000) | 0;
  const minutes = (seconds / 60) | 0;
  return `${pad2zero(minutes)}:${pad2zero(seconds % 60)}`;
}

export const getElementByClassName = (className: string) => document.getElementsByClassName(className)[0];
