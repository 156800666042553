import React, {useEffect, useState} from "react";
import {Part} from "./Part";
import {addIdleEventListener, animate, eventController, registerImages} from "./utils";
import squirrelRightEar from "./animations/static/ear.png";
import squirrelRightEarOutline from "./animations/static/ear-outline.png";
import squirrelHead from "./animations/static/head.png";
import squirrelHeadOutline from "./animations/static/head-outline.png";

registerImages({
  "right-ear": squirrelRightEar,
  "right-ear-outline": squirrelRightEarOutline,
  "head": squirrelHead,
  "head-outline": squirrelHeadOutline,
});

export function SquirrelHead(props: {
  show: boolean,
  index: number,
  scale: number,
  color: string,
  eating: boolean;
}) {
  const {show, index, eating} = props;

  const [earIdleStep, setEarIdleStep] = useState(0);
  const [, setStopEarIdleEvents] = useState<() => void | undefined>();
  useEffect(() => setStopEarIdleEvents(eventController(
          () => addIdleEventListener("ear", 6, 4, 1000, 4000,
              setEarIdleStep),
          show)),
      [show]);

  const [headIdleStep, setHeadIdleStep] = useState(0);
  const [, setStopHeadIdleEvents] = useState<() => void | undefined>();
  useEffect(() => setStopHeadIdleEvents(eventController(
          () => addIdleEventListener("head", 7, 4, 2000, 5000,
              setHeadIdleStep),
          show)),
      [show]);

  const [headEatingStep, setHeadEatingStep] = useState(0);

  const [, setStopCurrentAnimation] = useState<(() => void) | undefined>();

  useEffect(() => {
    setStopCurrentAnimation(stopCurrentAnimation => {
      stopCurrentAnimation && stopCurrentAnimation();
      return eating
          ? animate(64, 2, setHeadEatingStep,() => setStopCurrentAnimation(undefined))
          : undefined;
    });
  }, [eating]);

  const earRotate = `rotate(${4 * (earIdleStep <= 3 ? -earIdleStep : -(7 - earIdleStep))}deg)`;
  const headLeft = 362;
  let headTopOffset;
  if (headEatingStep) {
    const headEatingFrame = (headEatingStep - 1) % 8;
    headTopOffset = 8 * (headEatingFrame < 4 ? headEatingFrame : 7 - headEatingFrame);
  } else {
    headTopOffset = 4 * (headIdleStep <= 4 ? headIdleStep : 8 - headIdleStep)
  }
  const headTop = 400 + headTopOffset;

  return (
      <React.Fragment>
        <Part show={show}
              index={index}
              top={headTop} left={headLeft} width={300} height={318} scale={props.scale}
              color={props.color}
              transform={earRotate} transformOrigin={[238, 260]}
              name="right-ear"
        />
        <Part show={show}
              index={index}
              top={headTop} left={headLeft} width={770} height={724} scale={props.scale}
              color={props.color}
              name="head"
        />
      </React.Fragment>
  );
}