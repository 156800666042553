import React, {useEffect, useState} from "react";
import {Part} from "./Part";
import {animate, registerImages} from "./utils";
import squirrelLeftArm from "./animations/static/left-arm.png";
import squirrelLeftArmOutline from "./animations/static/left-arm-outline.png";
import squirrelRightArm from "./animations/static/arm.png";
import squirrelRightArmOutline from "./animations/static/arm-outline.png";

registerImages({
  "left-arm": squirrelLeftArm,
  "left-arm-outline": squirrelLeftArmOutline,
  "right-arm": squirrelRightArm,
  "right-arm-outline": squirrelRightArmOutline,
});


let lastEating = false;

export function SquirrelArms(props: {
  show: boolean,
  index: number,
  scale: number,
  color: string,
  eating: boolean;
}) {
  const [armsStep, setArmsStep] = useState(0);
  useEffect(() => {
    if (lastEating !== props.eating) {
      lastEating = props.eating;
      animate(10, 4, step => step && setArmsStep(props.eating ? step : step + 10),
          () => {});
    }
  }, [props.eating]);

  const rightArmRotate = `rotate(${4 * (armsStep <= 10 ? -armsStep : -(21 - armsStep))}deg)`;
  const leftArmRotate = `rotate(${6 * (armsStep <= 10 ? armsStep : (21 - armsStep))}deg)`;

  return (
      <React.Fragment>
        <Part show={props.show}
              index={props.index}
              left={330} top={1200}
              width={400} height={430}
              scale={props.scale}
              transform={rightArmRotate} transformOrigin={[0, 70]}
              color={props.color}
              name="right-arm"
        />
        <Part show={props.show}
              index={props.index}
              left={785} top={1210}
              width={294} height={451}
              scale={props.scale}
              transform={leftArmRotate} transformOrigin={[224, 43]}
              color={props.color}
              name="left-arm"
        />
      </React.Fragment>
  );
}