import React, {useEffect} from "react";
import {Part} from "./Part";
import {SquirrelHead} from "./SquirrelHead";
import {SquirrelTail} from "./SquirrelTail";
import {SquirrelArms} from "./SquirrelArms";
import {AllSquirrels, Trick} from "./Items";
import {TrickStretch} from "./animations/tricks/stretch/TrickStretch";
import squirrelBody from "./animations/static/body.png";
import squirrelBodyOutline from "./animations/static/body-outline.png";
import squirrelBelly from "./animations/static/belly.png";
import squirrelBellyOutline from "./animations/static/belly-outline.png";
import {registerImages} from "./utils";

registerImages({
  "body": squirrelBody,
  "body-outline": squirrelBodyOutline,
  "belly": squirrelBelly,
  "belly-outline": squirrelBellyOutline,
});

export function SquirrelView(props: {
  show: boolean,
  scale: number,
  index: number,
  doTrick?: Trick,
  trickDone(): void;
  eating: boolean;
}) {
  const {show, scale, index, doTrick, trickDone} = props;

  const squirrel = AllSquirrels[index];
  const color = squirrel.body;
  const bellyColor = squirrel.belly;

  useEffect(() => {
    if (doTrick && !["Wag Tail", "Stretch"].includes(doTrick.name)) {
      // no animations yet, done immediately:
      trickDone();
    }
  }, [doTrick, trickDone]);

  const stretchTrickPlaying = doTrick?.name === "Stretch";
  return (
      <>
        <TrickStretch
            show={show && stretchTrickPlaying}
            index={index}
            scale={scale}
            color={color}
            bellyColor={bellyColor}
            trickDone={trickDone}
        />
        <SquirrelTail
            show={show && !stretchTrickPlaying}
            index={index}
            scale={scale}
            color={color}
            doTrick={doTrick?.name === "Wag Tail"}
            trickDone={trickDone}
        />
        <Part show={show && !stretchTrickPlaying}
              index={index}
              left={240} top={1016}
              width={1020} height={1200}
              scale={scale}
              color={color}
              name="body"
        />
        <Part show={show && !stretchTrickPlaying}
              index={index}
              left={240 + 324} top={1016 + 268}
              width={400} height={750}
              scale={scale}
              color={bellyColor}
              name="belly"
        />
        <SquirrelHead
            show={show && !stretchTrickPlaying}
            index={index}
            scale={scale}
            color={color}
            eating={props.eating}
        />
        <SquirrelArms
            show={show && !stretchTrickPlaying}
            index={index}
            scale={scale}
            color={color}
            eating={false}
        />
      </>
  );
}