import {Part} from "../../../Part";
import {useEffect, useState} from "react";
import {animate, registerImages, scaledPx as scaledVw} from "../../../utils";
import trickStretch from "./sheet.png";
import trickStretchOutline from "./sheet-outline.png";

registerImages({
  "trick-stretch": trickStretch,
  "trick-stretch-outline": trickStretchOutline,
});

const frames = [0, 1, 2, 2, 2, 2, 2, 2, 1, 0];

export function TrickStretch(props: {
  show: boolean,
  index: number,
  scale: number,
  color: string,
  bellyColor: string,
  trickDone(): void,
}) {
  const scaledPx = (px?: number) => scaledVw(Math.round((px ?? 0) * props.scale));
  const [step, setStep] = useState(0);
  const {show, trickDone} = props;
  useEffect(() => {
    show && animate(frames.length, 8, setStep, trickDone);
  }, [show, trickDone]);
  return (
      <>
        <div style={{
          display: props.show ? undefined : "none",
          position: "absolute",
          top: scaledPx(1030),
          left: scaledPx(550),
          width: scaledPx(450),
          height: scaledPx(1070),
          backgroundColor: props.bellyColor,
        }}/>
        <Part
            show={props.show}
            index={props.index}
            name="trick-stretch"
            left={240-14} top={1016-868}
            width={1365} height={2073}
            sheetCols={3} sheetRows={1}
            step={frames[step]}
            scale={props.scale}
            color={props.color}
        />
      </>
  )
}