import React from "react";
import {classNames} from "./utils";

export function ShopItem(props: {
  icon: string,
  name: string,
  label: any,
  badge?: number,
  disabled: boolean,
  disabledLabel?: string,
  unlocked: boolean,
  onClick(disabled: boolean): void;
}) {
  const disabled = props.disabled || !props.unlocked;
  return (
      <tr className={classNames`${disabled} disabled`}>
        <td>
          <div className={classNames`panel-item-icon ${props.unlocked} ${props.icon}`}
               onClick={props.unlocked ? () => props.onClick(props.disabled) : undefined}
          >
            {props.disabledLabel && props.disabled && props.unlocked &&
                <div className="panel-item-disabled-label">{props.disabledLabel}</div>
            }
            {props.unlocked && props.badge !== undefined &&
                <div className="panel-item-badge">
                  {props.badge}
                </div>
            }
          </div>
        </td>
        <td>
          <p>
            {props.unlocked ? props.name : "???"}
          </p>
          <p>
            {props.label}
          </p>
        </td>
      </tr>
  );
}

