import React from "react";
import {scaledPx} from "./utils";
import {xpOfNeededXP} from "./Items";

export function LevelAndXP(props: {
  xp: number,
  level: number,
  onClick(): void,
}) {
  const iconWidth = 638;
  const iconHeight = 574;
  const iconFillHeight = 540;
  const iconFillTopOffset = 15;
  const iconScale = 150 / iconHeight;
  const {xp, neededXP} = xpOfNeededXP(props.level, props.xp);
  const heartOffsetY = iconScale * (iconFillHeight * (1 - (xp / neededXP)) + iconFillTopOffset);
  return (
      <div className="level-and-xp"
           style={{
             width: scaledPx(iconWidth * iconScale),
             height: scaledPx(iconHeight * iconScale),
           }}
           onClick={props.onClick}
      >
        <div className="level-and-xp-fill"
             style={{
               clip: `rect(${scaledPx(heartOffsetY)}, auto, auto, auto)`,
             }}
        />
        <div className="level-and-xp-outline"
        />
        <div className="level-and-xp-label"
        >{props.level}</div>
      </div>
  );
}